<template>
  <div v-if="show" class="text-modal-overlay" @click="handleClose">
    <div class="text-modal-container" @click.stop>
      <div class="text-modal-content">
        <!-- <h2 class="modal-title">{{ title }}</h2> -->
        <div class="text-modal-body">
          <slot></slot>
        </div>
        <div class="text-modal-footer">
          <slot name="footer">
            <button class="text-modal-button" :style="{backgroundColor: getAssets?.primaryBrandColor || '#A6383C'}" @click="handleClose" >OK</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      "getAssets",
    ]),
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.text-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.text-modal-container {
  background: white;
  border-radius: 8px;
  padding: 45px 30px;
  max-width: 510px;
  width: 90%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.text-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.text-modal-body {
  color: #666;
  line-height: 1.6;
}

.text-modal-footer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  gap: 10px;
}

.text-modal-button {
  width: 100%;
  height: 40px;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  gap: 10px;
  transition: background-color 0.1s;

}

.text-modal-button:hover {
  background-color: #d3112e;
}
</style>
