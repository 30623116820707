<template>
  <div class="footer-layout">
    <!-- <div class="user-info">
      <avatar-image
        v-if="getUser.profilePhotoURL"
        width="45"
        height="45"
        :image="getUser.profilePhotoURL"
      />
      <avatar-image v-else width="45" height="45" :image="defaultAvatar()" />
      <div>
        <div class="d-flex align-items-center gap-2">
          <p class="user-info--name">{{ getUser.name }}</p>
          <p
            v-if="getUser.title && getUser.title !== ''"
            class="user-info--role"
          >
            ({{ getUser.title }})
          </p>
        </div>
        <p class="user-info--gmail">
          <a v-if="getUser.email" :href="'mailto:' + getUser.email">{{
            getUser.email
          }}</a>
          <template v-if="getUser.phone">
            <a :href="'tel:' + getUser.phone">{{ getUser.phone }}</a>
          </template>
        </p>
      </div>
    </div> -->
    <div class="footer-disclaimer">
      <p>
        The actual interest rate, payment amounts, and associated costs you
        incur may be greater than initially estimated. {{ getCompanyName }} does
        not guarantee the above estimate(s), term(s), or fee(s).
      </p>
    </div>
    <outlined-button
      class="custom-outlined-button"
      text="Download Scenario"
      color="#000000"
      text-color-hover="var(--primary-color)"
      no-border
      no-background
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import OutlinedButton from "@/components/commons/atoms/OutlinedButton";
// import AvatarImage from "@/components/Icons/AvatarImage";
import { mapGetters } from "vuex";

export default {
  name: "FooterLayout",
  components: { OutlinedButton },
  data() {
    return {
      user: {
        role: "report creator",
        name: "Amanda Gilbert",
        gmail: "amanda@locklearlendingteam.com",
        phone: "(615) 933-2080",
        avatar: require("@/assets/images/home-page/avatar.svg"),
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getUser",
      "getAssets",
      "getCompanyName",
    ]),
  },
  methods: {
    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },
  },
};
</script>

<style lang="scss">
.footer-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 28px 40px 28px 36px;
  background: #f6f8fa;
  border-top: 1px solid #9aa2b866;

  .custom-outlined-button {
    padding: 0;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 20px;
    &--role {
      white-space: nowrap;
      color: #000000;
      opacity: 0.5;
    }
    &--name {
      white-space: nowrap;
      font-weight: 600;
      line-height: 21px;
      color: #000000;
    }
    &--gmail {
      display: flex;
      flex-wrap: wrap;
      line-height: 21px;
      a {
        color: #000000;
      }
    }
  }

  .footer-disclaimer {
    // border-left: 1px solid #99aaaa;
    border-right: 1px solid #99aaaa;
    padding: 0 15px;
    margin: 0 15px;

    p {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

@media all and (max-width: 1200px) {
  .footer-layout {
    flex-wrap: wrap;

    .footer-disclaimer {
      order: 3;
      border-left: none;
      border-right: none;
      margin: 15px 0 0;
      border-top: 1px solid #99aaaa;
      padding: 15px 0;
    }
  }
}

@media all and (max-width: 991px) {
  .footer-layout {
    flex-direction: column;
    align-items: flex-start;

    .user-info {
      border-bottom: 1px solid #99aaaa;
      padding: 15px 0;
      width: 100%;
    }

    .footer-disclaimer {
      margin-top: 0;
    }
  }
}

@media all and (max-width: 899px) {
  .footer-layout {
    .user-info {
      &--gmail {
        flex-direction: column;
        a {
          text-align: left;
        }
        .dash {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: 599px) {
  .footer-layout {
    padding: 30px 14px;
    .user-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
