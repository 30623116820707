const state = {
  orgId: "",
  prospectId: "",
  userData: {},
  portalAssets: {},
  portalTabs: [],
  companyName: "",
  borrower: true,
  borrowerName: "",
  coBorrowerName: "",
  plaidData: {},
  platform: "",
  isCreditMonitoringEnabled: false,
  isHVRUpdated: false,
  isAutoLogin: false,
  isSidebarHidden: false,
  showWelcomeModal: false,
  isDataEncrypted: false,
  applications: [],
  appStatuses: [],
  currAppStatus: '',
  loDetails: {},
};
const getters = {
  getOrgId: (state) => state.orgId,
  getProspectId: (state) => state.prospectId,
  getUser: (state) => state.userData,
  getAssets: (state) => state.portalAssets,
  getTabs: (state) => state.portalTabs,
  getCompanyName: (state) => state.companyName,
  getBorrower: (state) => state.borrower,
  getBorrowerName: (state) => state.borrowerName,
  getCoBorrowerName: (state) => state.coBorrowerName,
  getPlaidData: (state) => state.plaidData,
  getPlatform: (state) => state.platform,
  getIsCreditMonitoringEnabled: (state) => state.isCreditMonitoringEnabled,
  getIsHVRUpdated: (state) => state.isHVRUpdated,
  getIsAutoLogin: (state) => state.isAutoLogin,
  getIsSidebarHidden: (state) => state.isSidebarHidden,
  getShowWelcomeModal: (state) => state.showWelcomeModal,
  getIsDataEncrypted: (state) => state.isDataEncrypted,
  getApplications: (state) => state.applications,
  getAppStatuses: (state) => state.appStatuses,
  getCurrAppStatus: (state) => state.currAppStatus,
  getLoDetails: (state) => state.loDetails
};

const actions = {
  fetchOrgId({ commit }, data) {
    commit("setOrgId", data);
  },

  fetchProspectId({ commit }, data) {
    commit("setProspectId", data);
  },

  fetchUser({ commit }, data) {
    commit("setUser", data);
  },

  fetchAssets({ commit }, data) {
    commit("setAssets", data);
  },

  fetchTabs({ commit }, data) {
    commit("setTabs", data);
  },

  fetchCompanyName({ commit }, data) {
    commit("setCompanyName", data);
  },

  fetchBorrower({ commit }, data) {
    commit("setBorrower", data);
  },

  fetchBorrowerName({ commit }, data) {
    commit("setBorrowerName", data);
  },

  fetchCoBorrowerName({ commit }, data) {
    commit("setCoBorrowerName", data);
  },

  fetchPlaidData({ commit }, data) {
    commit("setPlaidData", data);
  },

  fetchPlatform({ commit }, data) {
    commit("setPlatform", data);
  },

  fetchIsCreditMonitoringEnabled({ commit }, data) {
    commit("setIsCreditMonitoringEnabled", data);
  },

  fetchIsHVRUpdated({ commit }, data) {
    commit("setIsHVRUpdated", data);
  },

  fetchIsAutoLogin({ commit }, data) {
    commit("setIsAutoLogin", data);
  },

  fetchIsSidebarHidden({ commit }, data) {
    commit("setIsSidebarHidden", data);
  },

  fetchShowWelcomeModal({ commit }, data) {
    commit("setShowWelcomeModal", data)
  },

  fetchIsDataEncrypted({ commit }, data) {
    commit("setIsDataEncrypted", data);
  },

  fetchApplications({ commit }, data) {
    commit('setApplications', data)
  },

  fetchAppStatuses({ commit }, data) {
    commit('setAppStatuses', data)
  },

  fetchCurrAppStatus({ commit }, data) {
    commit('setCurrAppStatus', data)
  },
  
  fetchLoDetails({ commit }, data) {
    commit("setLoDetails", data);
  },
};

const mutations = {
  setOrgId: (state, id) => (state.orgId = id),
  setUser: (state, user) => (state.userData = user),
  setAssets: (state, assets) => (state.portalAssets = assets),
  setTabs: (state, tabs) => (state.portalTabs = tabs),
  setCompanyName: (state, company) => (state.companyName = company),
  setBorrower: (state, borrower) => (state.borrower = borrower),
  setBorrowerName: (state, borrower_name) =>
    (state.borrowerName = borrower_name),
  setCoBorrowerName: (state, coborrower_name) =>
    (state.coBorrowerName = coborrower_name),
  setPlaidData: (state, plaid_data) => (state.plaidData = plaid_data),
  setProspectId: (state, prospect_id) => (state.prospectId = prospect_id),
  setPlatform: (state, platform) => (state.platform = platform),
  setIsCreditMonitoringEnabled: (state, creditMonitoringEnabled) =>
    (state.isCreditMonitoringEnabled = creditMonitoringEnabled),
  setIsHVRUpdated: (state, hvrupdate) => (state.isHVRUpdated = hvrupdate),
  setIsAutoLogin: (state, autologin) => (state.isAutoLogin = autologin),
  setIsSidebarHidden: (state, sidebarhidden) => (state.isSidebarHidden = sidebarhidden),
  setShowWelcomeModal: (state, showwelcomemodal) => (state.showWelcomeModal = showwelcomemodal),
  setIsDataEncrypted: (state, isencrypted) => (state.isDataEncrypted = isencrypted),
  setApplications: (state, applications) => (state.applications = applications),
  setAppStatuses: (state, appstatuses) => (state.appStatuses = appstatuses),
  setCurrAppStatus: (state, currstatus) => (state.currAppStatus = currstatus),
  setLoDetails: (state, loDetails) => (state.loDetails = loDetails)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
