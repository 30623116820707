<template>
  <div class="w-100 h-100">
    <loader v-if="loading" />
    <div v-else class="verification-screen">
      <img
        v-if="teamLogo"
        :src="teamLogo"
        class="text-center mb-5 logo"
        alt="Team-Logo"
      />
      <div class="two-factor-auth">
        <div v-if="!codeSent" class="box">
          <div class="title-desc">
            <h3 class="title">Verification</h3>
            <p class="desc">
              We will send a verification code to your<br />
              {{ sendCodeMethod === "message" ? "phone number" : "email address" }}
              to access your portal
            </p>
          </div>

          <div class="request-code">
            <h2>{{ sendCodeMethod === "message" ? userPhone : userEmail }}</h2>
            <a
              role="button"
              class="btn btn-primary"
              @click="
                codeSent = true;
                getOtp();
              "
              >Send code</a
            >
          </div>

          <div class="text-button">
            <p class="text-secondary">or choose another method</p>
            <div class="button-link">
              <p
                role="button"
                class="btn-link"
                @click="
                  codeSent = true;
                  sendCodeMethod = 'email';
                  getOtp();
                "
              >
                Receive an email
              </p>

              <p
                role="button"
                class="btn-link"
                @click="
                  codeSent = true;
                  sendCodeMethod = 'message';
                  getOtp();
                "
              >
                Receive a text
              </p>
            </div>
          </div>

          <div class="form-group mt-3" v-if="getCoBorrowerName !== ''">
            <label class="form-label borrower text-secondary">{{
              isBorrower === true ? "Borrower" : "Co-borrower"
            }}</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="isBorrower"
            >
              <option :value="true">{{ this.getBorrowerName }}</option>
              <option :value="false">{{ this.getCoBorrowerName }}</option>
            </select>
          </div>
        </div>

        <div v-else class="box">
          <div class="title-desc">
            <h3 class="title">Enter your verification code</h3>
            <p class="desc">
              Provide the verification code sent to
              {{ sendCodeMethod === "message" ? userPhone : userEmail }} to access your
              portal
            </p>
          </div>
          <div class="code-input">
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="firstDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="firstDigit"
              v-model="firstDigit"
              @input="firstDigit && !secondDigit ? $refs.secondDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="secondDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="secondDigit"
              v-model="secondDigit"
              @input="secondDigit && !thirdDigit ? $refs.thirdDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="thirdDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="thirdDigit"
              v-model="thirdDigit"
              @input="thirdDigit && !fourthDigit ? $refs.fourthDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="fourthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="fourthDigit"
              v-model="fourthDigit"
              @input="fourthDigit && !fifthDigit ? $refs.fifthDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="fifthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="fifthDigit"
              v-model="fifthDigit"
              @input="fifthDigit && !sixthDigit ? $refs.sixthDigit.focus() : ''"
            />
            <input
              type="text"
              pattern="\d*"
              class="input-box"
              :class="sixthDigit ? 'code-entered' : ''"
              maxlength="1"
              ref="sixthDigit"
              v-model="sixthDigit"
              @input="sixthDigit ? $refs.verify.focus() : ''"
            />
          </div>

          <div class="mt-4 request-code">
            <button
              ref="verify"
              class="btn btn-primary"
              @click="verifyOtp(otpCode)"
              :disabled="!otpCode"
            >
              Verify
            </button>
            <p
              style="
                font-weight: 400;
                font-size: 13px;
                text-align: center;
                margin-top: 32px;
                color: #666;
              "
            >
              By clicking Submit you consent to receive text message from
              {{ getLoDetails?.loCompanyName }}. Rates and terms may apply. Text
              STOP to opt out.
              <span
                :style="{
                  fontSize: '13px',
                  color:getAssets?.primaryBrandColor || '#A6383C',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }"
                @click="viewMore()"
                >More...</span
              >
            </p>
            <p class="mt-4" v-show="!showOtpTimer">
              Still haven't received a code? Has your code expired?
            </p>
            <button
              v-show="!showOtpTimer"
              role="button"
              class="mt-2 btn-link"
              @click="getOtp()"
            >
              Resend Code
            </button>
            <p class="mt-3 otp-rate-limit" v-show="showOtpTimer">
              Resend in 00:{{ otpTimer }} seconds
            </p>
          </div>
          <div class="text-button" v-show="!showOtpTimer">
            <p class="text-secondary">or choose another method</p>
            <div class="button-link">
              <p
                role="button"
                class="btn-link"
                @click="
                  sendCodeMethod = 'email';
                  getOtp();
                "
              >
                Receive an email
              </p>

              <p
                role="button"
                class="btn-link"
                @click="
                  sendCodeMethod = 'message';
                  getOtp();
                "
              >
                Receive a text
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :show="openModal" title="" @close="closeModal">
      <p>
        By clicking Verify you are providing express written consent for
        {{ getLoDetails?.loCompanyName }} and its affiliates, agents, and
        service providers to contact you at that number regarding products or
        services, including via autodialed and/or prerecorded or artificial
        voice calls and text messages (SMS and MMS), or email even if your
        telephone number is a cellular number or on a corporate, state, or the
        National Do Not Call Registry (DNC) or other do not contact list. You
        may also reach us at {{ getLoDetails?.loUserPhone }}. You may opt-out at
        any time by emailing us a Do Not Call Request at info@candid.inc and
        providing your phone number. Your consent is not required or a condition
        of any purchase. Message and data rates may apply. By communicating with
        us by phone you consent to calls being recorded and monitored.
      </p>
    </Modal>
  </div>
</template>

<script>
import Loader from "./Loader";
import { mapActions, mapGetters } from "vuex";
import Toasters from "../mixins/toasters";
import CryptoJS from "crypto-js";
import Modal from "../components/commons/atoms/Modal.vue";
import axios from "@/api-service";

export default {
  mixins: [Toasters],
  name: "Two-Factor-Auth",
  components: {
    Loader,
    Modal,
  },
  data() {
    return {
      loading: false,
      sendCodeMethod: "message",
      teamLogo: "",
      portalBg: "",
      userEmail: "",
      userPhone: "",
      firstDigit: "",
      secondDigit: "",
      thirdDigit: "",
      fourthDigit: "",
      fifthDigit: "",
      sixthDigit: "",
      isBorrower: true,
      theCompany: "",
      prospectId: "",
      orgId: "",
      platform: "",
      skipNotification: false,
      creditMonitoringEnabled: false,
      codeSent: false,
      showOtpTimer: false,
      otpTimer: "",
      openModal: false,
    };
  },
  async beforeMount() {
    this.theCompany = this.$route.params.pathMatch;
    let theUrl = this.$route.params.unique_key.split("&");
    this.prospectId = theUrl[0];
    this.orgId = theUrl[1];
    await this.getPortalAssets();
    await this.getUserAssetDetails();

    this.$route.query.defaultTab === "Mortgage-Tasks" && this.$route.query.verification
      ? this.fetchShowWelcomeModal(true)
      : this.fetchShowWelcomeModal(false);

    this.$route.query.hidesidebar === "true"
      ? this.fetchIsSidebarHidden(true)
      : this.fetchIsSidebarHidden(false);

    if (this.$route.query.platform === "salesforce") {
      this.platform = "salesforce";
      this.fetchPlatform(this.platform);

      if (this.$route.query.verification) {
        this.fetchIsAutoLogin(true);
        let decoded_otp = window.atob(this.$route.query.verification);
        let encrypted_otp = this.aesEncryption(decoded_otp);
        await this.verifyOtp(encrypted_otp);
      } else {
        this.fetchIsAutoLogin(false);
      }
    } else {
      this.platform = "";
      this.fetchPlatform(this.platform);
    }
  },
  computed: {
    ...mapGetters([
      "getAssets",
      "getTabs",
      "getBorrowerName",
      "getCoBorrowerName",
      "getIsDataEncrypted",
      "getLoDetails",
    ]),

    otpCode() {
      if (
        this.firstDigit &&
        this.secondDigit &&
        this.thirdDigit &&
        this.fourthDigit &&
        this.fifthDigit &&
        this.sixthDigit
      ) {
        return this.aesEncryption(
          this.firstDigit +
            this.secondDigit +
            this.thirdDigit +
            this.fourthDigit +
            this.fifthDigit +
            this.sixthDigit
        );
      }

      return "";
    },
  },
  watch: {
    portalBg(newVal) {
      if (newVal) {
        document.body.style.backgroundImage = `url(${newVal})`;
        document.body.style.backgroundPosition = "top-center";
        document.body.style.backgroundSize = "cover";
      }
    },

    isBorrower(newVal) {
      this.isBorrower = newVal;

      if (newVal) {
        if (this.getAssets.email) this.hideTheEmail(this.getAssets.email);
        if (this.getAssets.phone) this.hideThePhone(this.getAssets.phone);
      } else {
        if (this.getAssets.coBorrowerEmail)
          this.hideTheEmail(this.getAssets.coBorrowerEmail);
        if (this.getAssets.coBorrowerPhone)
          this.hideThePhone(this.getAssets.coBorrowerPhone);
      }
    },
  },
  mounted() {
    this.portalBg = this.getAssets?.agentPortalBackground;
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "fetchBorrower",
      "fetchAssets",
      "fetchTabs",
      "fetchOrgId",
      "fetchProspectId",
      "fetchCompanyName",
      "fetchBorrowerName",
      "fetchCoBorrowerName",
      "fetchPlaidData",
      "fetchPlatform",
      "fetchIsCreditMonitoringEnabled",
      "fetchIsAutoLogin",
      "fetchIsSidebarHidden",
      "fetchShowWelcomeModal",
      "fetchIsDataEncrypted",
      "fetchApplications",
      "fetchAppStatuses",
      "fetchCurrAppStatus",
      "fetchLoDetails",
    ]),
    //View more
    viewMore() {
      this.openModal = true;
    },

    //Close modal
    closeModal() {
      this.openModal = false;
    },

    async getPortalAssets() {
      this.loading = true;
      await this.$http
        .get("/account/GetAsset/" + this.prospectId + "/" + this.orgId)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 511) {
            this.$router.push("/");
            return;
          }

          document.title = resp?.assetData?.loCompany
            ? resp.assetData.loCompany + " Client Portal"
            : "Client Portal";

          const favicon = document.getElementById("favicon");
          favicon.setAttribute("href", resp.assetData?.roundColorTeamLogo);
          this.primaryColor = resp.assetData.primaryBrandColor || "#A6383C";
          this.teamLogo = resp.assetData.horizontalTeamLogo;
          this.portalBg = resp.assetData.agentPortalBackground;
          this.fetchAssets(resp.assetData);
          this.fetchApplications(resp?.applications || []);
          this.fetchAppStatuses(resp?.applicationStatus || []);
          this.fetchCurrAppStatus(resp?.currentStatus || "");
          this.fetchPlaidData(resp.plaidData);
          this.fetchOrgId(this.orgId);
          this.fetchProspectId(this.prospectId);
          this.fetchIsDataEncrypted(resp.assetData?.isDataEncrypted || false);
          this.fetchCompanyName(this.theCompany);
          this.fetchBorrowerName(resp.assetData.borrowerName);
          this.fetchCoBorrowerName(resp.assetData.coBorrowerName);

          let theTabs = resp.assetData.clientPortalDefaultTabs;
          let hvrTab = "Home-Value-Report";
          let getHVRIndex = theTabs.findIndex((el) => el === hvrTab);

          if (getHVRIndex >= 0) {
            theTabs.splice(getHVRIndex, 1);
            theTabs.unshift(hvrTab);
          }

          this.fetchTabs(theTabs);

          if (resp.assetData.email) this.hideTheEmail(resp.assetData.email);
          if (resp.assetData.phone) this.hideThePhone(resp.assetData.phone);

          let primary_color = resp.assetData.primaryBrandColor || "#A6383C";
          this.$localStore.set("primary-color", primary_color);

          this.$localStore.set("default-tabs", theTabs);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getOtp() {
      this.loading = true;

      const skipNoti = this.platform === "salesforce" ? true : false;

      this.firstDigit = "";
      this.secondDigit = "";
      this.thirdDigit = "";
      this.fourthDigit = "";
      this.fifthDigit = "";
      this.sixthDigit = "";

      await this.$http
        .get(
          "/account/prospectAuth" +
            "?isBorrower=" +
            this.isBorrower +
            "&msgOremail=" +
            this.sendCodeMethod +
            "&prospectId=" +
            this.prospectId +
            "&orgId=" +
            this.orgId +
            "&skipNotification=" +
            skipNoti
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status === 400) {
            this.codeSent = false;
            this.loading = false;
            this.error(resp.message);
          }

          if (resp.status === 200) {
            this.resendOtpTimer();

            if (this.codeSent) {
              setTimeout(() => {
                this.$refs.firstDigit.focus();
              }, 100);
            }

            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;

          setTimeout(() => {
            this.$refs.firstDigit.focus();
          }, 100);
        });
    },

    async verifyOtp(otp_code) {
      this.loading = true;

      let payLoad = {
        bisborrower: this.isBorrower,
        encryptedOTPCode: otp_code,
        orgId: this.orgId,
        recordId: this.prospectId,
      };

      await this.$http
        .patch("/account/validateClientPortalOtpCode", payLoad)
        .then((response) => {
          let resp = response.data;

          if (resp.status === 200) {
            this.$localStore.set("authorized", true);

            this.creditMonitoringEnabled = resp.prospectData.isCreditMonitoringEnabled;

            this.fetchUser(resp.prospectData);
            this.fetchBorrower(this.isBorrower);
            this.fetchIsCreditMonitoringEnabled(this.creditMonitoringEnabled);
            this.redirectUser();
          } else {
            this.firstDigit = "";
            this.secondDigit = "";
            this.thirdDigit = "";
            this.fourthDigit = "";
            this.fifthDigit = "";
            this.sixthDigit = "";
            this.error(resp.message);
            this.loading = false;

            if (this.codeSent) {
              setTimeout(() => {
                this.$refs.firstDigit.focus();
              }, 100);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    aesEncryption(otp) {
      let iv = CryptoJS.enc.Utf8.parse("qazwsxedcrfvtgby"); //key used in Python/Salesforce
      let key = CryptoJS.enc.Utf8.parse("zaqxswcdevfrbgtn"); //key used in Python/Salesforce

      return CryptoJS.AES.encrypt(otp, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
    },

    aesDecryption(encrypted) {
      let iv = CryptoJS.enc.Utf8.parse("qazwsxedcrfvtgby"); //key used in Python/Salesforce
      let key = CryptoJS.enc.Utf8.parse("zaqxswcdevfrbgtn"); //key used in Python/Salesforce

      return CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString(CryptoJS.enc.Utf8);
    },

    redirectUser() {
      if (
        Object.keys(this.$route.query).length &&
        this.$route.query.defaultTab &&
        this.getTabs.includes(this.$route.query.defaultTab)
      ) {
        this.$router.push({ name: this.$route.query.defaultTab });
      } else if (this.getTabs.includes(this.getAssets.clientPortalPinnedTab)) {
        this.$router.push({
          name: this.getAssets.clientPortalPinnedTab,
        });
      } else {
        this.$router.push({
          name: this.getTabs[0],
        });
      }

      setTimeout(() => {
        this.loading = false;
      }, 100);
    },

    resendOtpTimer() {
      this.showOtpTimer = true;
      this.otpTimer = 29;
      let downloadTimer = setInterval(() => {
        this.otpTimer--;
        this.otpTimer = this.otpTimer < 10 ? "0" + this.otpTimer : this.otpTimer;

        if (this.otpTimer <= 0) {
          this.showOtpTimer = false;
          clearInterval(downloadTimer);
        }
      }, 1000);
    },

    hideTheEmail(email) {
      if (this.getIsDataEncrypted) email = this.aesDecryption(email);

      let index = email.indexOf("@");
      let startIndex = (index * 0.2) | 0;
      let endIndex = (index * 1) | 0;
      this.userEmail =
        email.slice(0, startIndex) +
        email.slice(startIndex, endIndex).replace(/./g, "*") +
        email.slice(endIndex);
    },

    hideThePhone(phone) {
      if (this.getIsDataEncrypted) phone = this.aesDecryption(phone);

      this.userPhone = phone
        .replace(/\D+/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "xxx-xxx-$3");
    },
    async getUserAssetDetails() {
      // this.loading = true;
      await axios
        .get(
          `https://amrapi.candidcrm.com/user-assets/details?orgId=${this.orgId}&recordId=${this.prospectId}`
        )
        .then((response) => {
          let resp = response?.data?.loanOfficer;
          this.fetchLoDetails(resp);
          // this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
@import "./../assets/css/two-factor-auth.css";

.two-factor-auth button.verify-btn {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.two-factor-auth a {
  border-bottom: 1px solid var(--primary-color);
  border-bottom-style: dashed;
}
.checkbox input:checked + label:after {
  left: 9px;
}
.borrower {
  float: left;
}
.pointer {
  cursor: pointer;
}
</style>
